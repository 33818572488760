import { colors, spacing, transition } from '../../../themes/variables';
import { FelaCSS } from '../../fela/flowtypes';
import { SharedTypes as IProps } from './accordion-head';

interface IStyles {
  wrapper: FelaCSS;
  container: FelaCSS;
  text: FelaCSS;
  amount: FelaCSS;
  arrow: FelaCSS;
  customWrap: FelaCSS;
  fullWidth: FelaCSS;
}

const styles = ({
  opened,
  disabled,
  hideArrow,
  leaveBorder,
  noSidePaddings,
  containerHeight = 60,
  containerMinHeight,
  noBgColor,
  noRoundBorder,
  noTopBorder,
  onClick,
  openStatic,
  horizontalMargins,
  noFixedHeight,
  arrowStartDown,
  variant,
}: IProps): IStyles => {
  const isNormalVariant = variant === 'normal';
  const isH2Variant = variant === 'h2';

  return {
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      position: 'relative',
      fontDefault: 16,
      lineHeight: '16px',
      color: disabled ? colors.space15 : colors.space,
      ...(horizontalMargins && { marginHorizontal: spacing[4], marginVertical: spacing[4] }),
      marginVertical: isH2Variant ? 0 : undefined,
    },

    container: {
      flexGrow: 1,
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
      paddingLeft: noSidePaddings ? '0' : '16px',
      paddingRight: noSidePaddings ? '0' : '16px',
      ...(!noFixedHeight && { height: `${containerHeight}px` }),
      ...(containerMinHeight && { minHeight: `${containerMinHeight}px` }),
      borderTopWidth: noTopBorder ? '0' : '1px',
      borderTopStyle: isNormalVariant ? 'solid' : 'none',
      borderColor: opened && !leaveBorder ? colors.space04 : colors.space15,
      backgroundColor: opened && !noBgColor && isNormalVariant ? colors.space04 : 'transparent',
      transition: `background-color ${transition.fast} ease-in-out, border-color ${transition.fast} ease-in-out`,
      cursor: !onClick || openStatic ? 'default' : 'pointer',

      desktopDesign: noRoundBorder
        ? {}
        : {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: noRoundBorder ? '0' : '4px',
          },

      ':hover': {
        minWidthMd: {
          backgroundColor: noBgColor ? 'transparent' : colors.space04,
        },
      },
    },

    text: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '1px',
      fontDefault: 16,
      lineHeight: '27px',
      color: colors.space,
      width: '100%',
      ...(isH2Variant && {
        fontDefaultSemiBold: 22,
      }),
    },

    amount: {
      marginLeft: '7px',
    },

    arrow: {
      ...(arrowStartDown
        ? {
            transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
          }
        : {
            transform: opened ? 'rotate(0deg)' : 'rotate(-90deg)',
          }),
      transition: `transform ${transition.fast} ease-in-out`,
    },

    customWrap: {
      position: 'absolute',
      right: hideArrow ? '16px' : '40px',
      top: '50%',
      transform: 'translateY(-50%)',
    },

    fullWidth: {
      width: '100%',
    },
  };
};

export default styles;
